import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Cloud of kz Beta</p>
      <p>&copy; 2024 All rights reserved.</p>
    </footer>
  );
}

export default Footer;
