import '../App.css';
import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';
import fileToDownload from '../cloud/calculator_1-main.zip';
import fileToDownloadSecond from '../cloud/calculator_2-main.zip';

function CalculatorPart1() {
  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };

  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>Calculator app in Android Studio</h1>

          <div className='project-body-display-2'>
              <div>
                <div className='project-body-display-buttons'>

                  <a
                    className="download-button"
                    href={fileToDownload}
                    download="Project.zip"
                  >
                    Download Project v1
                  </a>

                  <a
                    className="download-button"
                    href={fileToDownloadSecond}
                    download="Project2.zip"
                  >
                    Download Project v2
                  </a>
                  <button className="open-channel-button" onClick={handleChannelClick}>YouTube Channel</button>

                </div>
              </div>

              <div>
                <VideoPlayer className="video-player" videoId="2UaTcCBHA_o" />
              </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CalculatorPart1;
