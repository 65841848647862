import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const [playerSize, setPlayerSize] = useState({ width: '100%', height: '100%' });

  useEffect(() => {
    const calculatePlayerSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const width = screenWidth * 0.4;
      const height = screenHeight * 0.4; 
      setPlayerSize({ width: `${width}px`, height: `${height}px` });
    };

    calculatePlayerSize(); // Initial calculation

    window.addEventListener('resize', calculatePlayerSize); // Recalculate on window resize

    return () => {
      window.removeEventListener('resize', calculatePlayerSize); // Cleanup
    };
  }, []);

  const opts = {
    width: playerSize.width,
    height: playerSize.height,
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId}/>;
  // return <YouTube videoId={videoId} {opts={opts}} />;
};

export default VideoPlayer;
