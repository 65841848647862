import '../App.css';
import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';
import fileToDownload from '../cloud/image_slider-main.zip';

function ImageSliderPart1() {
  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };
  

  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>Image Slider in Android Studio</h1>

          <div className='project-body-display-2'>
              <div>
                <div className='project-body-display-buttons'>
                
                <a className="download-button"
                  href={fileToDownload}
                  download="project.zip">
                  Download Project</a>
                  

                <button className="open-channel-button" onClick={handleChannelClick}>YouTube Channel</button>

                </div>
              </div>

              <div>
                <VideoPlayer className="video-player" videoId="6Z0oFa85gxM" />
              </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ImageSliderPart1;
