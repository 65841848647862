import '../App.css';
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import LatestProjects from './LatestProjects';


function Home() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/projects"); 
  };


  return (
    <div className="App">
      <Header/>
      <div className='home-body-background'>
        <div className='home-body-display'>
          <h1>Welcome</h1>
          <h4>Kz Beta – your gateway to the world of Android development. Explore, learn, and create amazing mobile experiences with us!</h4>
          <button className="start-button" onClick={handleButtonClick}>Start now</button>
        </div>
      </div>
      <LatestProjects/>
      <Footer/>
    </div>
  );
}

export default Home;
