import React from 'react';
import img_10 from "../images/img_project_10.png";
import img_11 from "../images/img_project_11.png";
import { useNavigate } from "react-router-dom";

const LatestProjects = () => {
    const navigate = useNavigate();
    const handleOpenProject = (a) => {
      navigate(`/projects/${a}`); 
    };
  return (
    <footer className="latest-projects-footer">
        <hr/>
        <h2>Latest Uploaded Projects</h2>
        <div className='projects-body-container-not-full'>
            <div className='projects-container-item' onClick={() => handleOpenProject("beometric-auth-fingerprint")}>
                <img src={img_10} alt="Backgound of Project" />
                <h3>Biometric Auth</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("flexbox-layout-part-1")}>
                <img src={img_11} alt="Backgound of Project" />
                <h3>FlexboxLayout Part 1</h3>
            </div>
          </div>
    </footer>
  );
}

export default LatestProjects;
