import '../App.css';
import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';
import fileToDownload from '../cloud/Flexbox.zip';


function FlexboxLayoutPart1() {
  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };

  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>FlexboxLayout | Part 1 | Android Studio</h1>

          <div className='project-body-display-2'>
              <div>
                <div className='project-body-display-buttons'>
                
                <a
                  className="download-button"
                  href={fileToDownload}
                  download="project.zip"
                >
                  Download Project
                </a>

                  <button className="open-channel-button" onClick={handleChannelClick}>YouTube Channel</button>

                </div>
              </div>

              <div>
                <VideoPlayer className="video-player" videoId="ZyJWmOpSesI" />
              </div>
          </div>

          <div className='implementation-parent'>
            <h3>Implementation</h3>

            <code>
              implementation 'com.google.android:flexbox:1.1.1'
            </code>
          </div>


        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default FlexboxLayoutPart1;
