import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';
import fileToDownload from '../cloud/BiometricAuth.zip'; // Import the file
// import fileToDownload from '../cloud/aaa.zip';


function BeometricAuthFingerprint() {
  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };

  return (
    <div className="App">
      <Header />
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>Biometric Auth | Fingerprint Scanner</h1>

          <div className='project-body-display-2'>
            <div>
              <div className='project-body-display-buttons'>
                <a className="download-button"
                  href={fileToDownload}
                  download="project.zip">
                  Download Project</a>
                  
                <button className="open-channel-button" onClick={handleChannelClick}>
                  YouTube Channel
                </button>
              </div>
            </div>

            <div>
              <VideoPlayer className="video-player" videoId="W6iuH9txu-U" />
            </div>
          </div>
          
          <div className='implementation-parent'>
            <h3>Implementation</h3>

            <code>
              implementation 'androidx.biometric:biometric:1.1.0'
            </code>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BeometricAuthFingerprint;
