import '../App.css';
import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import img_1 from "../images/my-baidibek-icon.png";
import star from "../images/star.png";


function MyBaidibek() {
  const handleChannelClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.betagames.mybaidibek', '_blank');
  };


  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>My Baidibek - Sale, Go, Use</h1>

          <div className='project-body-display-2'>
              <div>

                <div className='project-body-display-buttons'>
                
                    <button className="download-button" onClick={handleChannelClick}>Play Store</button>

                    <div className='grade-stars-container'>
                        <div>
                            <h1>5,0  -  15 reviews</h1>
                        </div>

                        <div>
                            <img src={star} alt='star'/>
                            <img src={star} alt='star'/>
                            <img src={star} alt='star'/>
                            <img src={star} alt='star'/>
                            <img src={star} alt='star'/>
                        </div>
                    </div>
                </div>

              </div>

              <div className='project-body-display-images'>
                <img src={img_1} alt='screenshot-1'/>
              </div>
          </div>
            <hr/>
          <div className='published-project-more-info-container'>
            <div className='published-project-more-info-box'>
                <div className='published-project-more-info-left'>
                    <h4>
                        Version
                    </h4>
                    <p>0.2</p>

                    <h4>
                    Requires Android
                    </h4>
                    <p>5.1 and up</p>
                    <h4>

                    In-app purchases
                    </h4>
                    <p>Free</p>
                </div>
                <div className='published-project-more-info-right'>
                    <h4>
                    Updated on
                    </h4>
                    <p>Oct 12, 2023</p>

                    <h4>
                    Downloads
                    </h4>
                    <p>10+ downloads</p>

                    <h4>
                    Download Size
                    </h4>
                    <p>43 MB</p>
                </div>
            </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default MyBaidibek;
