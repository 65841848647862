import React from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import img_1 from "../images/img_project_1.png";
import img_2 from "../images/img_project_2.png";
import img_3 from "../images/img_project_3.png";
import img_4 from "../images/img_project_4.png";
import img_5 from "../images/img_project_5.jpg";
import img_6 from "../images/img_project_6.jpg";
import img_7 from "../images/img_project_7.jpg";
import img_8 from "../images/img_project_8.png";
import img_9 from "../images/img_project_9.png";
import img_10 from "../images/img_project_10.png";
import img_11 from "../images/img_project_11.png";

import Header from './Header';
import Footer from './Footer';

function Projects() {
  const navigate = useNavigate();
  const handleOpenProject = (a) => {
    navigate(`/projects/${a}`); 
  };


  return (
    <div className="App">
        <Header/>
      <div className='projects-body-background'>
        <div className='projects-body-display'>
          <h2>List of Projects</h2>

          <div className='projects-body-container'>
            <div className='projects-container-item' onClick={() => handleOpenProject("full-screen-dialog")}>
                <img src={img_1} alt="Backgound of Project" />
                <h3>Full Screen Dialog</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("selecting-images-from-device")}>
                <img src={img_2} alt="Backgound of Project" />
                <h3>Selecting Images from Device</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("calculator-part-1")}>
                <img src={img_3} alt="Backgound of Project" />
                <h3>Calculator in Android Studio</h3>
            </div>
          </div>

          <div className='projects-body-container'>
            <div className='projects-container-item' onClick={() => handleOpenProject("qr-scanner-part-1")}>
                <img src={img_4} alt="Backgound of Project" />
                <h3>QR Scanner</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("music-player-part-1")}>
                <img src={img_5} alt="Backgound of Project" />
                <h3>Music Player</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("image-slider-part-1")}>
                <img src={img_6} alt="Backgound of Project" />
                <h3>Image slider Part 1</h3>
            </div>
          </div>

          <div className='projects-body-container'>
            <div className='projects-container-item' onClick={() => handleOpenProject("generate-qr-code")}>
                <img src={img_7} alt="Backgound of Project" />
                <h3>Generate QR Code</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("image-slider-part-2")}>
                <img src={img_8} alt="Backgound of Project" />
                <h3>Image slider Part 2</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("swipe-refresh-layout-part-1")}>
                <img src={img_9} alt="Backgound of Project" />
                <h3>SwipeRefreshLayout Part 1</h3>
            </div>
          </div>

          <div className='projects-body-container-not-full'>
            <div className='projects-container-item' onClick={() => handleOpenProject("beometric-auth-fingerprint")}>
                <img src={img_10} alt="Backgound of Project" />
                <h3>Biometric Auth</h3>
            </div>
            <div className='projects-container-item' onClick={() => handleOpenProject("flexbox-layout-part-1")}>
                <img src={img_11} alt="Backgound of Project" />
                <h3>FlexboxLayout Part 1</h3>
            </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Projects;
