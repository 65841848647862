import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Verify from './0lJXeUDnHdtyfHfBGMFr8Sz8Et4FjJhVgLh2mP4mXkoDZ1rFgymIsYeTGCopttpb'
import FullScreenDialog from './projects/FullScreenDialog'
import SelectingImagesFromDevice from './projects/SelectingImagesFromDevice';
import CalculatorPart1 from './projects/CalculatorPart1';
import QrScannerPart1 from './projects/QrScannerPart1';
import MusicPlayerPart1 from './projects/MusicPlayerPart1';
import ImageSliderPart1 from './projects/ImageSliderPart1';
import GenerateQrCode from './projects/GenerateQrCode';
import ImageSliderPart2 from './projects/ImageSliderPart2';
import SwipeRefreshLayoutPart1 from './projects/SwipeRefreshLayoutPart1';
import BeometricAuthFingerprint from './projects/BeometricAuthFingerprint';
import FlexboxLayoutPart1 from './projects/FlexboxLayoutPart1';
import PublishedApps from './pages/PublishedApps';
import AttackAndWarOffline from './published/AttackAndWarOffline';
import MyBaidibek from './published/MyBaidibek';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/published-apps" element={<PublishedApps />} />
          


          <Route path="/projects/full-screen-dialog" element={<FullScreenDialog />} />
          <Route path="/projects/selecting-images-from-device" element={<SelectingImagesFromDevice />} />
          <Route path="/projects/calculator-part-1" element={<CalculatorPart1 />} />
          <Route path="/projects/qr-scanner-part-1" element={<QrScannerPart1 />} />
          <Route path="/projects/music-player-part-1" element={<MusicPlayerPart1 />} />
          <Route path="/projects/image-slider-part-1" element={<ImageSliderPart1 />} />
          <Route path="/projects/generate-qr-code" element={<GenerateQrCode />} />
          <Route path="/projects/image-slider-part-2" element={<ImageSliderPart2 />} />
          <Route path="/projects/swipe-refresh-layout-part-1" element={<SwipeRefreshLayoutPart1 />} />
          <Route path="/projects/beometric-auth-fingerprint" element={<BeometricAuthFingerprint />} />
          <Route path="/projects/flexbox-layout-part-1" element={<FlexboxLayoutPart1 />} />



          <Route path="/attack-and-war-offline" element={<AttackAndWarOffline />} />
          <Route path="/my-baidibek" element={<MyBaidibek />} />


          <Route path="/.well-known/acme-challenge/0lJXeUDnHdtyfHfBGMFr8Sz8Et4FjJhVgLh2mP4mXkoDZ1rFgymIsYeTGCopttpb" element={<Verify />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
