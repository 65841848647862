import React from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import img_1 from "../images/attack-and-war-icon.png";
import img_2 from "../images/my-baidibek-icon.png";

import Header from './Header';
import Footer from './Footer';


function PublishedApps() {
  const navigate = useNavigate();
  const handleOpenProject = (a) => {
    navigate(`/${a}`); 
  };


  return (
    <div className="App">
        <Header/>
      <div className='projects-body-background'>
        <div className='projects-body-display'>
          <h2>List of Projects</h2>


          <div className='published-projects-body-container-not-full'>
            <div className='published-projects-container-item' onClick={() => handleOpenProject("attack-and-war-offline")}>
                <img src={img_1} alt="Backgound of Project" />
                <h3>Attack and War: Offline</h3>
            </div>

            <div className='published-projects-container-item' onClick={() => handleOpenProject("my-baidibek")}>
                <img src={img_2} alt="Backgound of Project" />
                <h3>My Baidibek - Sale, Go, Use</h3>
            </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default PublishedApps;
